// Sidebar.js
// import { useState } from "react";
import Nav from "react-bootstrap/Nav";
// import { NavLink } from "react-router-dom";
import rytlifeLogo from "../../../assets/images/icons/rytlifeLogo.jpeg";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Button from "react-bootstrap/Button";
import logo from "../../../assets/images/logo.png";
import { AiOutlineAppstore } from "react-icons/ai";
import { RiUserSettingsLine } from "react-icons/ri";
import { CgLogOff } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../../Route/RouteDetails";

const Sidebar = () => {
  console.log("=========", rytlifeLogo);
  const navigate = useNavigate();

  const Logoutfun = () => {
    // localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenORG-admin");
    localStorage.removeItem("ORGadminData");
    navigate(`../${AdminRoute?.Auth?.Login}`);
  };
  return (
    <div id="sidebar-wrapper" className="sidebar">
      <Nav.Link eventKey="/" href="/dashboard">
        <div className="row pt-4 mb-3">
          <div className="col-12 text-center">
            <img src={logo} className="logo" alt="logo" />
          </div>
        </div>
      </Nav.Link>

      {/* =========== */}
      <Nav.Link eventKey="/" href="/#/">
        <div id="menuOption">
          <AiOutlineAppstore size={"1.7em"} style={{ marginRight: 10 }} />
          Admin Dashboard
        </div>
      </Nav.Link>
      <div id="menuOption">
        {" "}
        <RiUserSettingsLine size={"1.7em"} style={{ marginRight: 10 }} />
        Admin Settings
      </div>

      <div style={{ display: "flex", flexFlow: "row" }}>
        {/* <div>dsad</div> */}
        <div style={{ marginLeft: 80 }}>
          <Nav.Link eventKey="/" href="/#/EmplyeeData">
            <div id="menuOption">Employee Data</div>
          </Nav.Link>
          <Nav.Link eventKey="/" href="/#/NewSubscription">
            <div id="menuOption">Subscription</div>
          </Nav.Link>
        </div>
      </div>
      <Nav.Link eventKey="/" href="/#/ResponsePlan">
        <div id="menuOption">
          {" "}
          <AiOutlineAppstore size={"1.7em"} style={{ marginRight: 10 }} />
          Response Plan
        </div>
      </Nav.Link>
      <Nav.Link eventKey="/" href="/#/Login">
        <div id="menuOption" onClick={() => Logoutfun()}>
          <CgLogOff size={"1.9em"} style={{ marginRight: 10 }} />
          Logout
        </div>
      </Nav.Link>
    </div>
  );
};

export default Sidebar;
