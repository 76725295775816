import React,{useState,useEffect} from "react";
import Sidebar from "./Layout/Sidebar";
import Header from "../Dashboard/Layout/Header";
import { FaCheck } from "react-icons/fa6";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails"
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from "react-router-dom";
function Subscription() {
  const navigate = useNavigate()

  const [paymentList, setPaymentList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [PlanDetails, setPlanDetails] = useState({});
  const [CurrentPlan, setCurrentPlan] = useState([]);
  
  useEffect(() => {
    MyPlanlfeaturesss();
    getCurrentSession();
  }, []);


  const getRow = () => {
    const newArray = [];
    paymentList.map((val) => {
      var createArray = [
        val[0]?.order_id,  
        val[0]?.amount      ,
      ];

      newArray.push(createArray);
    });

    return newArray.map((val, index) => (
      <div tabIndex={index}>
        {" "}
        <Row_
          array_={val}
          color={"#125049"}
          background={index % 2 ? "#e5dfd7" : "#FFF9F1"}
        />
      </div>
    ));
  };

  const getCurrentSession = () => {
    setLoading(true)
    try {
      API.Payment.paymentHistory().then((response) => {
        if (response.status === 200) {
          console.log("========>paymentHistory",response.data.data_all);
          setPaymentList(response.data.data_all);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const MyPlanlfeaturesss = () => {
    try {
      API.Payment.MyPlanlfeatures()
        .then((response) => {
          if (response.status === 200) {
            setCurrentPlan(response.data.data)
            setPlanDetails(response.data.plandetails)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }








  const content = [
    "Employee Analytics",
    "Wellness Tracker",
    "Playground",
    "Support Group",
    "Workshops",
    "Focussed Group",
    "AI Therepist",
    "Leadership Initiatives",
    "Daily Notes",
  ];
  const header = ["Order ID", "Plan", "Price", "Payment Date", "Expiry Date"];
 
  const Row_ = ({ array_, color }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: color,
          padding: "10px 20px",
          borderRadius: "30px",
          margin: "10px",
          color: "#125049",
          fontWeight: "bold",
        }}
      >
        {array_.map((val, index) => (
          <div tabIndex={index} style={{ width: "20%", textAlign: "center" }}>
            {val}
          </div>
        ))}
      </div>
    );
  };


  const handleUpgradePlan = () => {
    navigate(`../${AdminRoute.Common.SelectPlan}?plan=upgrade`)
  }

  //jsx
  return (
    <div id="container">
      <div>
        <Sidebar />
      </div>
      <div style={{ width: "100%" }}>
        <Header title={"Subscription"} />

        <div className="gloock-font "
          style={{
            margin: "10px 10px",
            fontSize: 20,
            fontWeight: "bold",
            color: "#125049",
          }}
        >
          My Current Plan
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // paddingInline: "10px",
          }}
        >
          <div style={styles.conentContainer1}>
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "60px",
                marginTop: "20px",
              }}
            >
              <div style={{ fontSize: 22 }}>{PlanDetails?.name}</div>
              <div style={{ fontSize: 14 }}>Rs.{PlanDetails?.amount}/-</div>
              <div style={{ fontSize: 12 }}>Monthly Subscription</div>
            </div>
            <div>
            {PlanDetails?.feature?.length && Object.entries(PlanDetails?.feature[0]).map(([key, value], index) => {
                return (
               <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <FaCheck style={{ color: "#81AC2F" }} size={"1.4em"} />
                  <div
                    tabIndex={index}
                    style={{ color: "#125049", marginLeft: "10px" }}
                  >
                    {key}
                  </div>
                </div>
               )
              })
              }
            </div>

            <Button onClick={() => handleUpgradePlan()} style={styles.button}>Upgrade Plan</Button>
          </div>

          {/*  */}
          <div style={styles.conentContainer}>
            <Row_ array_={header} color={"#fff"} />
            {Loading ?
                              <div className="alert alert-warning" role="alert">
                                Loading...
                              </div>
                              :
                              paymentList.length === 0 ?
                                <div className="alert alert-warning" role="alert">
                                  Data Not Found
                                </div>
                                :
            
                                paymentList?.length && paymentList.map((val, index) => {
              <div tabIndex={index}>
                {" "}
                <Row_ array_={getRow} color={"#FFF9F1"} />
              </div>
           })
          }
          </div>
        </div>
        <div style={styles.chartContainer3}>
          <div>Emergency Contact : 8133 212 2213</div>
          <div>All rights reserved by RYTLife 2023</div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;

const styles = {
  conentContainer: {
    backgroundColor: "#e5dfd7",
    marginInline: "10px",
    width: "60%",
    borderRadius: "10px",
    height: "100%",
    paddingInline: "20px",
    paddingBottom: "10px",
    paddingTop: "10px",
    maxHeight: "60vh",
    overflow: "auto",
  },
  conentContainer1: {
    backgroundColor: "#e5dfd7",
    marginInline: "10px",
    width: "40%",
    borderRadius: "10px",
    height: "100%",
    paddingInline: "20px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  chartContainer: {
    height: "300px",
    margin: "10px 0px",
    borderRadius: "10px",
    backgroundColor: "#FFF9F1",
  },
  chartContainer2: {
    // height: "300px",
    margin: "10px 0px",
    borderRadius: "10px",
    backgroundColor: "#FFF9F1",
  },
  chartContainer3: {
    // height: "300px",
    margin: "30px 10px",
    borderRadius: "20px",
    backgroundColor: "#e5dfd7",
    padding: "10px",
    fontSize: 12,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    color: "#858483",
  },
  chartBox: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: "20px",
    paddingTop: "10px",
    fontWeight: "bold",
  },
  blockTitle: {
    // fon,
  },
  button: {
    margin: "20px 0px",
    fontSize: 14,
    padding: "10px 30px",
    backgroundColor: "#F2BA3F",
    fontWeight: "bold",
    borderRadius: "22px",
    color: "#fff",
    textAlign: "center",
    border: "none"
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "15px 10px",
    fontSize: 12,
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    minHeight: "100%",
    minWidth: "10px",
    backgroundColor: "#F2BA3F",
    margin: "7px 20px 7px 10px ",
    borderRadius: "30px",
  },
  date: {
    borderRadius: "15px",
    backgroundColor: "#FFF9F1",
    fontSize: 11,
    padding: "3px 10px",
    margin: "auto",
    fontWeight: "bold",
    color: "#858483",
    width: "max-content",
  },
};
