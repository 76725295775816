import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import { NavLink } from "react-router-dom";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import baseApi from "../../Api/config";
import userImg from "../../assets/images/icons/userIMG.jpg";

import DefaultUserImg from "../../assets/images/icons/Icon awesome-user-circle.png";

const Register1 = () => {
  //   const navigate = useNavigate();

  //   const initialValues = {
  //     fullname: "",
  //     email: "",
  //     Password: "",
  //     Companyname: "",
  //     Companyaddress: "",
  //     CNnumber: "",
  //     GSTnumber: "",
  //     Sector: "",
  //   };
  //   const [formData, setFormData] = useState(initialValues);
  //   const [formErrors, setFormErrors] = useState({});
  //   const [isSubmit, setIsSubmit] = useState(false);
  //   const [Sector, Setsector] = useState("");

  //   const RegisterValidate = (values, Sector) => {
  //     const errors = {};
  //     // const checkMobile = /^[789]\d{9}$/
  //     const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
  //     var regularExpression =
  //       /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  //     if (!values?.fullname) {
  //       errors.fullname = "Full Name is required";
  //     }
  //     if (!values?.email) {
  //       errors.email = "Email is required";
  //     } else if (!emailRegrex.test(values?.email)) {
  //       errors.email = "Enter valid Email";
  //     }
  //     if (!values?.Password) {
  //       errors.Password = "Password is required";
  //     } else if (!regularExpression.test(values?.Password)) {
  //       errors.Password = "Enter valid Password";
  //     }

  //     if (!values?.Companyname) {
  //       errors.Companyname = "Company name is required";
  //     }
  //     if (!values.Companyaddress) {
  //       errors.Companyaddress = "Company address is required";
  //     }
  //     if (!values.CNnumber) {
  //       errors.CNnumber = "CN Number is required";
  //     }
  //     if (!values.GSTnumber) {
  //       errors.GSTnumber = "GST Number is required";
  //     }
  //     if (!Sector) {
  //       errors.Sector = "Sector Name is required";
  //     }
  //     return errors;
  //   };

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   };

  //   useEffect(() => {
  //     if (Object.keys(formErrors).length === 0 && isSubmit) {
  //       //navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);  // temporary baypass to verification page

  //       try {
  //         API.Auth.Register({
  //           data: {
  //             name: formData?.fullname,
  //             email: formData?.email,
  //             password: formData?.Password,
  //             Companyname: formData?.Companyname,
  //             Companyaddress: formData?.Companyaddress,
  //             CNnumber: formData?.CNnumber,
  //             GSTnumber: formData?.GSTnumber,
  //             Sector: Sector,
  //             role: "Orgadmin",
  //             is_active: "true",
  //           },
  //         })
  //           .then((response) => {
  //             console.log(response.data);
  //             if (response?.data?.status === 1) {
  //               navigate(`../${AdminRoute?.Auth?.Login}`);
  //               toast.success(response?.data?.message);
  //             } else {
  //               toast.error(response?.data?.message);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("Error", error.message);
  //             console.log(error.response);
  //             console.log(error);
  //           });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   }, [formErrors]);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setFormErrors(RegisterValidate(formData, Sector));
  //     setIsSubmit(true);
  //   };

  // ===============================================================

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailLoc = JSON.parse(queryParams.get("email"));
  const mobile_noLoc = JSON.parse(queryParams.get("mobile_no"));
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");

  const initialValues = {
    Name: "",
    Email: emailLoc,
    PhoneNumber: mobile_noLoc,
    birthDate: "",
    Gender: "",
    Surname: "",
  };
  useEffect(() => {
    if (emailLoc) {
      setFormData({
        ...formData,
        email: emailLoc,
      });
    }
    if (mobile_noLoc) {
      setFormData({
        ...formData,
        PhoneNumber: mobile_noLoc,
      });
    }
  }, [])
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // ==========Upload image api===========

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      // console.log(err.res);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    console.log(`${baseApi.baseurl}images/${image}`);

    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const RegisterValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    const nameRegex = /^[A-Za-z\s-]+$/;
    // const ageRegex = /^\d+$/;
    const minNumber = 1;
    const maxNumber = 120;

    if (!values?.Name) {
      errors.Name = "Please enter first name!";
    } else if (!nameRegex.test(values?.Name)) {
      errors.Name = "Please enter valid name!";
    }
    if (!values?.Surname) {
      errors.Surname = "Please enter last name!";
    } else if (!nameRegex.test(values?.Surname)) {
      errors.Surname = "Please enter valid last name!";
    }
    if (!values?.PhoneNumber) {
      errors.PhoneNumber = "Please enter mobile no!";
    }
    if (!values?.Email) {
      errors.Email = "Please enter email!";
    } else if (!emailRegrex.test(values?.Email)) {
      errors.Email = "Please enter valid email!";
    }
    // if (!values?.Age) {
    //   errors.Age = "Please enter DOB!";
    // } 
    //  if (!ageRegex.test(values?.Age)) {
    //   errors.Age = "Please enter valid DOB!";
    // } else if (values?.Age < minNumber || values?.Age > maxNumber) {
    //   errors.Age = "Please enter valid DOB!";
    // }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    if (!values?.Gender) {
      errors.Gender = "Please select gender!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      localStorage.setItem("RegUserData", JSON.stringify(formData));
      navigate(`../${AdminRoute?.Auth?.Register}`);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    // console.log("======ProfileImage>", ProfileImage);
    // setFormData({ ...formData["ProfileImage"], ProfileImage });
    localStorage.setItem("ProfileImage", JSON.stringify(ProfileImage));
  }, [ProfileImage]);

  const disableFutureDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    // return today;
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate.toISOString().split('T')[0];
  }
  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto">
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex">
        <div className="col-12 col-md-6">
          <img className="logo" width={200} src={rtylifeLogo} />
          <h1 className="heading">Reclaim control over your</h1>
          <p className="subheading">Mental Health journey</p>
          <p>
            through our online therapy programs, guided by our compassionate
            team of psychologists.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <div className="register p-4 py-4">
            <div className="row mb-2">
              <h2 className="heading mt-0 mb-4">Personal Details</h2>
            </div>
            <form className="mt-3">
              <div className="d-flex justify-content-center position-relative edit-profile-image mb-3">

                {spinner ? (
                  <>
                    <div className="edit-profile-page custom-drag-box">
                      <div
                        className="spinner-grow text-warning mx-auto"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="1"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </div>
                  </>
                ) : ProfileImage ? (
                  <>
                    <div className="edit-profile-page custom-drag-box">
                      <div className="d-flex flex-column align-items-center">
                        <i
                          className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                          aria-hidden="true"
                        ></i>
                        <div
                          className="form-upload-photo-preview2"
                          style={{
                            backgroundImage: `url(${ProfileImage})`,
                          }}
                        ></div>
                        <span className="custom-drag-box-text d-none">
                          Image Uploaded
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          tabIndex="1"
                          title=" "
                          onChange={(e) => handleUpload(e)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="edit-profile-page custom-drag-box d-flex justify-content-center align-items-center">
                      <p className="edit-icon position-absolute">
                        <i className="fa fa-edit"></i>
                      </p>

                      <img className="reg-user-img" src={DefaultUserImg} />
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="1"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                <div className="col-12 col-lg-6">
                  <label>
                    <span className="text-danger fw-bold">*</span> First Name:
                  </label>
                  <input
                    type="text"
                    name="Name"
                    placeholder="Enter First Name"
                    value={formData.Name}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <p className="text-danger">{formErrors?.Name}</p>
                </div>

                <div className="col-12 col-lg-6">
                  <label>
                    <span className="text-danger fw-bold">*</span> Last Name:
                  </label>
                  <input
                    type="text"
                    name="Surname"
                    placeholder="Enter Last Name"
                    value={formData.Surname}
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    required
                  />
                  <p className="text-danger">{formErrors?.Surname}</p>
                </div>

                <div className="col-12 col-lg-6">
                  <label>
                    <span className="text-danger fw-bold">*</span> E-Mail ID:
                  </label>
                  <input
                    type="email"
                    name="Email"
                    defaultValue={emailLoc}
                    placeholder="Enter your E-Mail ID"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                  <p className="text-danger">{formErrors?.Email}</p>
                </div>

                <div className="col-12 col-lg-6">
                  <label>
                    <span className="text-danger fw-bold">*</span> Mobile
                    Number:
                  </label>

                  <input
                    type="text"
                    name="PhoneNumber"
                    value={formData.PhoneNumber}
                    autoComplete="off"
                    placeholder="Enter Mobile Number"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <p className="text-danger">{formErrors?.PhoneNumber}</p>
                  {/* {error && <p className="text-danger">{error}</p>} */}
                </div>

                {/* <div className="col-12 col-lg-6">
                  <label>
                    <span className="text-danger fw-bold">*</span> Date Of Birth:
                  </label>

                  <input
                    type="date"
                    name="Age"
                    value={formData.Age}
                    autoComplete="off"
                    placeholder="DD/MM/YYYY"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <p className="text-danger">{formErrors?.Age}</p>
                </div> */}

                <div className="col-12 col-lg-6">
                  <label><span className="text-danger fw-bold">*</span> Birth Date:</label>
                  <input
                    type="date"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    autoComplete="off"
                    max={disableFutureDate()}

                    required
                  />
                  <p className="text-danger">{formErrors?.birthDate}</p>
                </div>

                <div className="col-12 col-lg-6">
                  <label>
                    <span className="text-danger fw-bold">*</span> Gender:
                  </label>

                  <select
                    name="Gender"
                    className="gender-select"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Your Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="other">other</option>
                  </select>

                  <p className="text-danger">{formErrors?.Gender}</p>
                  {/* {error && <p className="text-danger">{error}</p>} */}
                </div>

                {/* <div className="col-12 col-lg-6">
                    <label>
                      <span className="text-danger fw-bold">*</span> BirthDate:
                    </label>
                    <input
                      type="date"
                      placeholder="Enter BirthDate"
                      name="age"
                      value={formData.age}
                      onChange={handleChange}
                      autoComplete="off"
                      max={disableFutureDate()}
                      required
                    />
                    <p className="text-danger">{formErrors?.birthDate}</p>
                  </div> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    className="button_common float-end"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>

            {/* old form starts */}

            {/* <form>
                            <h2 className="heading fw-600 pb-2 mt-0">Register Personal Details</h2>
                            <div className="col">
                                <label className="pb-2">
                                    * Full Name
                                </label>
                                <input
                                    type="text"
                                    name="fullname"
                                    value={formData.fullname}
                                    placeholder="Enter your Fullname"
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                <p className="text-danger">{formErrors?.fullname}</p>
                            </div>
                            <div className="col">
                                <label className="pb-2">
                                    * Phone
                                </label>
                                <input
                                    type="text"
                                    name="Companyname"
                                    value={formData.Companyname}

                                    placeholder="Enter your Company Name"
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                <p className="text-danger">{formErrors?.Companyname}</p>
                            </div>
                           

                            <div className="col">
                                <label className="pb-2">
                                    * Address
                                </label>
                                <input
                                    type="text"
                                    name="CNnumber"
                                    value={formData.CNnumber}

                                    placeholder="Enter your CN number"
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                                <p className="text-danger">{formErrors?.CNnumber}</p>
                            </div>

                            <div className="col">
                                <label className="pb-2">
                                    * Gender
                                </label>
                                <select
                        className="time-select"
                        // onChange={(e)=>Setsector(e.target.value)}
                      >
                        <option value={"Male"}>Male</option>
                        <option value={"Female"}>Female</option>
                        
                      </select>
                                   
                                
                                <p className="text-danger">{formErrors?.GSTnumber}</p>
                            </div>

                            <div className="col">
                                <label className="pb-2">
                                    *E-Mail ID
                                </label>
                                <input
                                    type="email"
                                    placeholder="Enter E-Mail ID"
                                    name="email"
                                    value={formData.email}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    required
                                />
                                 <p className="text-danger">{formErrors?.email}</p>
                            </div>

                             


                          


                            
                            <Button
                                type="submit"
                                className="button_common w-100 my-3 mb-3 text-center"
                                 onClick={(e) => handleSubmit(e)}
                            >
                                Submit
                            </Button>
                        </form> */}

            {/* ****************Ends *************** */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register1;
