import React, { useState, useEffect } from "react";
import Sidebar from "./Layout/Sidebar";
import Header from "../Dashboard/Layout/Header";
import moment from 'moment';
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegFileExcel } from "react-icons/fa";
import API from "../../Api/Api";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import baseApi from "../../Api/config";

import DefaultUserImg from "../../assets/images/icons/Icon awesome-user-circle.png";
// import { Pagination, Form } from "react-bootstrap";
//  import fs from 'fs'

function EmployeeViewDetails() {
    const [BulkUpload, setBulkUpload] = useState("");
    const ORGadminData = JSON.parse(localStorage.getItem("ORGadminData"));
    const AuthToken = localStorage.getItem("AuthTokenORG-admin");
    const [Loading, setLoading] = useState(false);
    const [employee, setemployee] = useState([]);
    const [employeecsv, setemployeecsv] = useState([]);
    const [Details, setDetails] = useState([]);

    const [Tab, setTab] = useState([]);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    console.log(ORGadminData);
    // const content = [
    //     "Employee Analytics",
    //     "Wellness Tracker",
    //     "Playground",
    //     "Support Group",
    //     "Workshops",
    //     "Focussed Group",
    //     "AI Therepist",
    //     "Leadership Initiatives",
    //     "Daily Notes",
    // ];
    // const header = [
    //     "##",
    //     "Name",
    //     "Gender",
    //     "DOB",
    //     "Email-id",
    //     "Mobile No.",
    //     "Designation",
    //     "Sector",
    //     "Leader",
    //     "View Details",
    // ];
    // const tableValue = [
    //     [
    //         "Akshay Mahajan",
    //         "Male",
    //         "10-12-2023",
    //         "akshay.mahajan@gmail.com",
    //         "+91 9874563210",
    //         "Technical Lead",
    //         "Innovation Labs",
    //         "Yes",
    //     ],
    //     [
    //         "Akshay Mahajan",
    //         "Male",
    //         "10-12-2023",
    //         "akshay.mahajan@gmail.com",
    //         "+91 9874563210",
    //         "Technical Lead",
    //         "Innovation Labs",
    //         "Yes",
    //     ],
    //     [
    //         "Akshay Mahajan",
    //         "Male",
    //         "10-12-2023",
    //         "akshay.mahajan@gmail.com",
    //         "+91 9874563210",
    //         "Technical Lead",
    //         "Innovation Labs",
    //         "Yes",
    //     ],
    //     [
    //         "Akshay Mahajan",
    //         "Male",
    //         "10-12-2023",
    //         "akshay.mahajan@gmail.com",
    //         "+91 9874563210",
    //         "Technical Lead",
    //         "Innovation Labs",
    //         "Yes",
    //     ],
    //     [
    //         "Akshay Mahajan",
    //         "Male",
    //         "10-12-2023",
    //         "akshay.mahajan@gmail.com",
    //         "+91 9874563210",
    //         "Technical Lead",
    //         "Innovation Labs",
    //         "Yes",
    //     ],
    //     [
    //         "Akshay Mahajan",
    //         "Male",
    //         "10-12-2023",
    //         "akshay.mahajan@gmail.com",
    //         "+91 9874563210",
    //         "Technical Lead",
    //         "Innovation Labs",
    //         "Yes",
    //     ]

    // ];
    //bulk upload api call
    const BulkUploadServiceCenter = (e) => {
        console.log(e.target.files);
        console.log(AuthToken);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", AuthToken);
        var formdata = new FormData();
        formdata.append("csv", e.target.files[0], e.target.files[0]?.name);
        var requestOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(`${baseApi.baseurl}users/user_upload`, requestOptions)
            .then((response) => response.text())
            .then((result) => handleFileState(result))
            .catch((error) => console.log("error", error));
        window.$(".bulkupload").modal("hide");
    };

    const handleFileState = (result) => {
        setBulkUpload(JSON.parse(result));
        console.log("object=====>>>", BulkUpload);
    };

    const handleSelect = (Key) => {

        if (Key === "active") {
            getemployee();
        }
        if (Key === "inactive") {
            getemployeelistuploadedbycsv();
        }
    };

    //download function
    const download = (e) => {
        e.preventDefault();
        // alert("Download");
        const response = {
            file_path:
                "https://ai.rytlife.com/api/images/1711869265_.csv",
        };
        window.open(response.file_path);
    };

    const getRow = () => {
        const newArray = [];
        employee?.length && employee?.map((val, index) => {
            var createArray = [
                index + 1,
                val?.fullname,
                val?.gender,
                <span>{moment(val?.dob).format("DD/MM/YYYY")}</span>,
                val?.email,
                val?.phone,
                val?.designation,
                val?.Sector,
                val?.lead,
                val?.viewdetails,
            ];

            newArray.push(createArray);
        });

        return newArray.map((val, index) => (
            <div tabIndex={index}>
                {" "}
                <Row_
                    array_={val}
                    color={"#125049"}
                    background={index % 2 ? "#e5dfd7" : "#FFF9F1"}
                />
            </div>
        ));
    };

    const getRowCSV = () => {
        const newArray = [];
        employeecsv?.length && employeecsv?.map((val, index) => {
            var createArray = [
                index + 1,
                val?.fullname,
                val?.gender,
                <span>{moment(val?.dob).format("DD/MM/YYYY")}</span>,
                val?.email,
                val?.phone,
                val?.designation,
                val?.Sector,
                val?.lead,
                val?.viewdetails,
            ];

            newArray.push(createArray);
        });

        return newArray.map((val, index) => (
            <div tabIndex={index}>
                {" "}
                <Row_
                    array_={val}
                    color={"#125049"}
                    background={index % 2 ? "#e5dfd7" : "#FFF9F1"}
                />
            </div>
        ));
    };

    useEffect(() => {
        getemployee()
        getemployeelistuploadedbycsv()
    }, []);

    const getemployee = (Key) => {
        console.log(ORGadminData?.data?.org_name);
        setLoading(true)
        try {
            API.EmplyeeData.EmployeeList({
                key: Key,
                cname: ORGadminData?.data?.org_name,
            }).then((response) => {
                console.log("employeelist", response?.data?.data);
                setemployee(response?.data?.data);
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }



    const getemployeelistuploadedbycsv = (Key) => {
        console.log(ORGadminData?.data?.org_name);
        setLoading(true)
        try {
            API.EmplyeeData.EmployeeListbycsv({
                cname: ORGadminData?.data?.org_name
            }).then((response) => {
                console.log("employeelist", response?.data?.data_all);
                setemployeecsv(response?.data?.data_all);
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    //jsx
    const Row_ = ({ array_, color, background }) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: background,
                    padding: color == "#fff" ? "25px 0px" : "10px 0px",
                    color: color,
                    fontWeight: "bold",
                    wordWrap: "break-word"
                }}
            >
                {array_.map((val, index) => (
                    <div
                        tabIndex={index}
                        style={{
                            textAlign: "center",
                            width:
                                index === 3
                                    ? "20%"
                                    : index + 1 != array_.length
                                        ? "10%"
                                        : "15%",
                        }}
                    >
                        {index + 1 != array_.length || color == "#fff" ? (
                            val
                        ) : (
                            <>
                                {val}
                                {/* <BsThreeDotsVertical
                  size={"1.5em"}
                  style={{ marginLeft: "10px" }}
                /> */}
                                {/* <FaRegFileExcel size={"2em"} style={{ marginLeft: "10px" }} /> */}
                            </>
                        )}
                    </div>
                ))}
                <div
                    className="modal fade bulkupload"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    style={{ height: "auto" }}

                // id="bulkupload"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title mt-0">Product Bulk Upload</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <p className="text-dark">Upload the CSV file, in correct format</p>
                                <div className="input-group mb-3">
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="bulkupload"
                                        onChange={(e) => BulkUploadServiceCenter(e)}
                                    />
                                    {/* <label className="input-group-text" htmlFor="bulkupload">
                  Upload File
                </label>  */}
                                </div>
                                <p className="mb-0">
                                    <a
                                        href="#"
                                        className="blueText text-decoration-underline"
                                        onClick={(e) => download(e)}
                                    >
                                        Download a Blank Sample Template CSV file htmlFor Bulk upload
                                    </a>
                                </p>
                            </div>
                            <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn addBtns waves-effect waves-light px-4 button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3"
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                {/* <button
                type="button"
                className="btn filledaddBtns waves-effect waves-light px-4"
              //onClick={(e) => BulkUploadServiceCenter(e)}
              >
                Add
              </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div id="container">
            <div>
                {" "}
                <Sidebar />
            </div>
            <div style={{ width: "100%" }}>
                <Header title={"Employee Data Details"} />

                <div className="col-md-12 mb-2 list-item employee-data-details-v-height px-3">
                    <div className="row" >
                        <div className="my-organization">
                            <div className="row gx-3" >
                                {/* {Details?.is_authorized === 0 ? */}

                                {/* <div className="col-12 col-md-4">
                                    <div className="approval-employee mb-3">
                                        <div className="employee-box">
                                            <div className='employee-box-inner employee-box-inner-first p-3 d-block'>
                                                <div className='text-div-sessions px-0 text-start'>
                                                    <div className="slot-div-name text-dark">fullname
                                                    </div>
                                                    <div className="slot-div-date small-text">role</div>
                                                </div>
                                                <div className="clock text-end small-text">
                                                    {moment(Details?.createdAt).format("LL")}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <button
                                                        type="submit"
                                                        className="create-new_notes button_common notes-btn transparent-btn btn btn-primary bg-white mb-0 mt-2"
                                                    onClick={(e) => GetRejectUser()}
                                                    >
                                                        <a className="" >Reject</a>
                                                    </button>
                                                </div>
                                                <div className='col-6'>
                                                    <button
                                                        type="submit"
                                                        className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                                    onClick={(e) => GetApproveUser()}
                                                    ><a className="" >Approve</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <div className="col-md-12 b-0">
                                    <div className="card-body payment-card-body profile-wrap">
                                        <div className="row">
                                            {/* <div className="col-2">
                                                <div className="row h-100">
                                                    <div className="col-12 d-flex align-items-center">
                                                        <div className="profile-image-box d-flex justify-content-center align-items-center mx-auto">
                                                            <img src={Details?.image ? Details?.image : DefaultUserImg}></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-12">
                                                <div className="row pt-3 pe-3 ml-5">
                                                    <div className="col-12">
                                                        <p className="mb-2">Personal Details</p>
                                                        <hr className="my-2" />
                                                    </div>
                                                    <div className="col-12">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="Full Name" className="form-label">
                                                                                    Full Name
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    readOnly
                                                                                    className="form-control"
                                                                                    placeholder="Full name"
                                                                                    aria-describedby="Full Name"
                                                                                // defaultValue={Details?.fullname}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="Email" className="form-label">
                                                                                    Email
                                                                                </label>
                                                                                <input
                                                                                    type="email"
                                                                                    className="form-control"
                                                                                    readOnly
                                                                                    placeholder="Email"
                                                                                    aria-describedby="Email"
                                                                                // defaultValue={Details?.email}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="Contact No" className="form-label">
                                                                                    Contact No.
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    readOnly
                                                                                    placeholder="Contact No."
                                                                                    aria-describedby="Contact No"
                                                                                // defaultValue={Details?.phone}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                       
                                                                        <div className="col-4">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="Assign Organization" className="form-label">
                                                                                    Select
                                                                                </label>
                                                                                <select className="form-select" aria-label="Default select example">
                                                                                    <option selected>select menu</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                    <option value="3">Three</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row justify-content-end'>
                                                        <div className='col-6'>
                                                            <button
                                                                type="submit"
                                                                className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                                            // onClick={(e) => GetAssignorg(e, val)}
                                                            >
                                                                <a className="" href="#">Assign</a></button>
                                                        </div>
                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* } */}

                                {/*----------------- Newly Deed Slider Code End here-----------*/}
                            </div>
                        </div>
                    </div>
                    {/* <h4 className='secheading mb-1 mt-3'>Organization List</h4>
                    <p className="mb-2">Here is a detail list of all your Organization.</p>
                    <div className="row mb-3" >
                        <div className="my-organization">
                            <div className="row gx-3" >


                                <div className="col-12 px-0">
                                    <Carousel responsive={responsive}>
                                        
                                        <div className="row">
                                            <div className="approval-employee mb-3 px-0">
                                                <div className="employee-box mx-3">
                                                    <div className='employee-box-inner employee-box-inner-first p-3 d-block'>
                                                        <div className='text-div-sessions px-0 text-start'>
                                                            <div className="slot-div-name text-dark">org_name</div>
                                                            <div className="slot-div-date small-text">role</div>
                                                        </div>
                                                        <div className="clock text-end small-text">
                                                        </div>
                                                    </div>
                                                    <div className='employee-box-inner mt-1 row text-start'>
                                                        <div className="col-6">
                                                            <p className='small-text my-2'>Sector</p>
                                                            <p className='text-dark fw-bold  my-2'>Sector</p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <p className='small-text  my-2'>Location</p>
                                                            <p className='text-dark fw-bold  my-2'>Companyaddress</p>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-end'>
                                                        <div className='col-6'>
                                                            <button
                                                                type="submit"
                                                                className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                                            // onClick={(e) => GetAssignorg(e, val)}
                                                            >
                                                                <a className="" href="#">Assign</a></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div style={styles.chartContainer3} className="mt-2 mb-0">
                    <div>Emergency Contact : 8133 212 2213</div>
                    <div>All rights reserved by RYTLife 2023</div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeViewDetails;

const styles = {
    conentContainer: {
        backgroundColor: "#e5dfd7",
        marginInline: "10px",
        width: "100%",
        borderRadius: "10px",
        height: "100%",
        paddingBottom: "10px",
        overflow: "auto",
    },
    conentContainer1: {
        backgroundColor: "#e5dfd7",
        marginInline: "10px",
        width: "40%",
        borderRadius: "10px",
        height: "100%",
        paddingInline: "20px",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    chartContainer: {
        height: "300px",
        margin: "10px 0px",
        borderRadius: "10px",
        backgroundColor: "#FFF9F1",
    },
    chartContainer2: {
        // height: "300px",
        margin: "10px 0px",
        borderRadius: "10px",
        backgroundColor: "#FFF9F1",
    },
    chartContainer3: {
        // height: "300px",
        margin: "30px 10px",
        borderRadius: "20px",
        backgroundColor: "#e5dfd7",
        padding: "10px",
        fontSize: 12,
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
        fontWeight: "bold",
        color: "#858483",
    },
    chartBox: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingInline: "20px",
        paddingTop: "10px",
        fontWeight: "bold",
    },
    blockTitle: {
        // fon,
    },
    button: {
        margin: "20px 0px",
        fontSize: 14,
        padding: "10px 30px",
        backgroundColor: "#F2BA3F",
        fontWeight: "bold",
        borderRadius: "22px",
        color: "#fff",
        textAlign: "center",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        margin: "15px 10px",
        fontSize: 12,
        justifyContent: "space-between",
        alignItems: "center",
    },
    line: {
        minHeight: "100%",
        minWidth: "10px",
        backgroundColor: "#F2BA3F",
        margin: "7px 20px 7px 10px ",
        borderRadius: "30px",
    },
    date: {
        borderRadius: "15px",
        backgroundColor: "#FFF9F1",
        fontSize: 11,
        padding: "3px 10px",
        margin: "auto",
        fontWeight: "bold",
        color: "#858483",
        width: "max-content",
    },
};
