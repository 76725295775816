import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";

const Register = () => {
  const navigate = useNavigate();
  const initialValues = {
    fullname: "",
    email: "",
    Password: "",
    Companyname: "",
    Companyaddress: "",
    CNnumber: "",
    GSTnumber: "",
    Sector: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Sector, Setsector] = useState("");
  const regdata = JSON.parse(localStorage.getItem("RegUserData"));
  const ProfileImg = JSON.parse(localStorage.getItem("ProfileImage"));
  const [loading, setLoading] = useState(false);
  const [ApiError, setApiError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const RegisterValidate = (values, Sector) => {
    const errors = {};
    // const checkMobile = /^[789]\d{9}$/
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    if (!values?.email) {
      errors.email = "Email is required";
    } else if (!emailRegrex.test(values?.email)) {
      errors.email = "Enter valid Email";
    }
    if (!values?.Password) {
      errors.Password = "Password is required";
    } else if (!regularExpression.test(values?.Password)) {
      errors.Password = "Enter valid Password";
    }

    if (!values?.Companyname) {
      errors.Companyname = "Company name is required";
    }
    if (!values.Companyaddress) {
      errors.Companyaddress = "Company address is required";
    }
    if (!values.CNnumber) {
      errors.CNnumber = "CN Number is required";
    }
    if (!values.GSTnumber) {
      errors.GSTnumber = "GST Number is required";
    }
    if (!Sector) {
      errors.Sector = "Sector Name is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);
      try {
        API.Auth.Register({
          data: {
            fullname: `${regdata?.Name}${" " + regdata?.Surname}`,
            email: regdata?.Email,
            phone: regdata?.PhoneNumber,
            dob: regdata?.birthDate,
            gender: regdata?.Gender,
            image: ProfileImg,
            Companyemail: formData?.email,
            password: formData?.Password,
            organization_name: formData?.Companyname,
            org_name: formData?.Companyname,
            Companyaddress: formData?.Companyaddress,
            CNnumber: formData?.CNnumber,
            GSTnumber: formData?.GSTnumber,
            Sector: Sector,
            role: "Orgadmin",
            is_active: "true",
          },
        })
          .then((response) => {
            console.log(response.data);
            if (response?.data?.status === 1) {
              navigate(`../${AdminRoute?.Auth?.Login}`);
              toast.success(response?.data?.message);
            } else {
              // toast.error(response?.data?.message);
              setApiError(response?.data?.message);
            }
            localStorage.removeItem("RegUserData");
            localStorage.removeItem("ProfileImage");
            setLoading(false);
          })
          .catch((error) => {
            console.log("Error", error.message);
            console.log(error.response);
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setApiError(error?.message);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData, Sector));
    setIsSubmit(true);
  };

  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto">
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex">
        <div className="col-12 col-md-6">
          <img className="logo" width={200} src={rtylifeLogo} />
          <h1 className="heading">Reclaim control over your</h1>
          <p className="subheading">Mental Health journey</p>
          <p>
            through our online therapy programs, guided by our compassionate
            team of psychologists.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <div className="register p-4 py-5">
            {/* ********** Old form starts ******** */}
            <form>
              <h2 className="heading fw-600 pb-2 mt-0">
                Register Company Details
              </h2>

              <div className="col">
                <label className="pb-2">* Company Name</label>
                <input
                  type="text"
                  name="Companyname"
                  value={formData.Companyname}
                  placeholder="Enter your Company Name"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.Companyname}</p>
              </div>
              <div className="col">
                <label className="pb-2">* Company Address</label>
                <input
                  type="text"
                  name="Companyaddress"
                  value={formData.Companyaddress}
                  placeholder="Enter your Company Address"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.Companyaddress}</p>
              </div>

              <div className="col">
                <label className="pb-2">* CIN Number</label>
                <input
                  type="text"
                  name="CNnumber"
                  value={formData.CNnumber}
                  placeholder="Enter your CN number"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.CNnumber}</p>
              </div>

              <div className="col">
                <label className="pb-2">* GST Number</label>
                <input
                  type="text"
                  name="GSTnumber"
                  value={formData.GSTnumber}
                  maxLength={16}
                  placeholder="Enter your GST Number"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.GSTnumber}</p>
              </div>

              <div className="col">
                <label className="pb-2">*Company E-Mail ID</label>
                <input
                  type="email"
                  placeholder="Enter E-Mail ID"
                  name="email"
                  value={formData.email}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <p className="text-danger">{formErrors?.email}</p>
              </div>

              <div className="mb-2 float-start w-100 position-relative">
              <label className="pb-2">
                  *Set Password
                </label>
                {/* <div className=""> */}
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Set Password"
                  name="Password"
                  value={formData.Password}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  ></i>
                </span>
                {/* </div> */}
                <p className="text-danger">{formErrors?.Password}</p>
              </div>

              {/* <div className="col">
                <label className="pb-2">Set Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter Password"
                  name="Password"
                  value={formData.Password}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                  <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  ></i>
                </span>
                
              </div> */}

              <div className="col">
                <label className="pb-2">* Sector</label>
                {/* <input
                  type="text"
                  name="Sector"
                  value={formData.Sector}
                  placeholder="Enter your Sector"
                  onChange={handleChange}
                  autoComplete="off"
                /> */}
                <select
                  className="time-select"
                  onChange={(e) => Setsector(e.target.value)}
                >
                  <option value={"Finance"}>Finance</option>
                  <option value={"Healthcare"}>Healthcare</option>
                  <option value={"Technology"}>Technology</option>
                  <option value={"Retail"}>Retail</option>
                  <option value={"Energy"}>Energy</option>
                  <option value={"Manufacturing"}>Manufacturing</option>
                  <option value={"Transportation"}>Transportation</option>
                  <option value={"Construction"}>Construction</option>
                  <option value={"Agriculture"}>Agriculture</option>
                  <option value={"Education"}>Education</option>
                  <option value={"Hospitality"}>Hospitality</option>
                  <option value={"Real-Estate"}>Real Estate </option>
                  <option value={"Services"}>Services </option>
                  <option value={"Media"}>Media</option>
                  <option value="other">other</option>
                </select>
                <p className="text-danger">{formErrors?.Sector}</p>
              </div>
              <p className="text-danger">{ApiError}</p>
              <Button
                type="submit"
                className="button_common w-100 my-3 mb-3 text-center"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </Button>
              {loading ? <div className="loader_orange"></div> : <></>}
            </form>

            {/* **************** Old form end ************ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
