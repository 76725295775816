import React, { useState, useEffect } from "react";
import Sidebar from "./Layout/Sidebar";
import Header from "../Dashboard/Layout/Header";
import moment from "moment";
import API from "../../Api/Api";
import Tabs from "react-bootstrap/Tabs";
import AdminRoute from "../../Route/RouteDetails";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
// import { Pagination, Form } from "react-bootstrap";
//  import fs from 'fs'
import { Pagination } from "react-bootstrap";
import baseApi from "../../Api/config";

// --------------------modal------------------------
import Modal from "react-modal";
import { Border } from "react-bootstrap-icons";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
    border: "0",
  },
};
// --------------------modal------------------------

function EmplyeeData() {
  const navigate = useNavigate();
  const [BulkUpload, setBulkUpload] = useState("");
  const ORGadminData = JSON.parse(localStorage.getItem("ORGadminData"));
  const AuthToken = localStorage.getItem("AuthTokenORG-admin");
  const [Loadingactive, setLoadingactive] = useState(false);

  const [employee, setemployee] = useState([]);
  const [employeecsv, setemployeecsv] = useState([]);
  const [Tab, setTab] = useState([]);
  const [LoadingCmsemployee, setLoadingCmsemployee] = useState(false);
  const location = useLocation();
  const [loading, setLoadings] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const [modalIsOpen, setIsOpen] = React.useState(false);

  const [currentPageinactive, setCurrentPageinactive] = useState(1);
  const [itemsPerPageinactive] = useState(50);
  const indexOfLastIteminactive = currentPageinactive * itemsPerPageinactive;
  const indexOfFirstIteminactive = indexOfLastItem - itemsPerPageinactive;

  // ---------------------modal----------------------
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setIsOpen(false);
  }

  // ---------------------modal----------------------

  //bulk upload api call
  const BulkUploadServiceCenter = (e) => {
    // console.log(e.target.files);
    // console.log(AuthToken);

    setLoadings(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", AuthToken);
    var formdata = new FormData();
    formdata.append("csv", e.target.files[0], e.target.files[0]?.name);
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${baseApi.baseurl}users/user_upload`, requestOptions)
      .then((response) => response.text(response?.data?.message))
      .then((result) => handleFileState(result))
      .catch((error) => console.log("error", error), setLoadings(false));
    closeModal();
    //Used to auto reload to same location
  };

  const handleFileState = (result) => {
    getemployeelistuploadedbycsv();
    setBulkUpload(JSON.parse(result));
    toast.success(JSON.parse(result?.data?.message));
    console.log(JSON.parse(result));

    // location.reload();
    setLoadings(false);
  };

  //download function
  const download = (e) => {
    e.preventDefault();
    // alert("Download");
    const response = {
      file_path: "https://ai.rytlife.com/api/images/1711869265_.csv",
    };
    window.open(response.file_path);
  };
  const handleSelect = (Key) => {
    if (Key === "active") {
      getemployee();
    }
    if (Key === "inactive") {
      getemployeelistuploadedbycsv();
    }
  };

  useEffect(() => {
    getemployee();
    getemployeelistuploadedbycsv();
  }, []);

  const getemployee = (Key) => {
    console.log(ORGadminData?.data?.org_name);
    setLoadingactive(true);
    try {
      API.EmplyeeData.EmployeeList({
        key: Key,
        cname: ORGadminData?.data?.org_name,
      }).then((response) => {
        console.log("employeelist", response?.data?.data);
        setemployee(response?.data?.data);
        setLoadingactive(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingactive(false);
    }
  };

  const getemployeelistuploadedbycsv = (Key) => {
    console.log(ORGadminData?.data?.org_name);
    setLoadingCmsemployee(true);
    setLoadings(true);
    try {
      API.EmplyeeData.EmployeeListbycsv({
        cname: ORGadminData?.data?.org_name.trim(),
      }).then((response) => {
        console.log("employeelist", response?.data?.data_all);
        setemployeecsv(response?.data?.data_all);
        setLoadingCmsemployee(false);
        setLoadings(false);
      });
    } catch (error) {
      console.log(error);
      setLoadings(false);
    }
  };

  const currentItems = employee.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(employee.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentItemsinactive = employeecsv?.slice(
    indexOfFirstIteminactive,
    indexOfLastIteminactive
  );
  const totalPagesinactive = Math.ceil(
    employeecsv?.length / itemsPerPageinactive
  );
  const handlePageChangeinactive = (page) => {
    setCurrentPageinactive(page);
  };

  //jsx
  // const Row_ = ({ array_, color, background }) => {
  //   return (

  //     <div
  //       className="modal fade bulkupload"
  //       tabIndex="-1"
  //       role="dialog"
  //       aria-labelledby="mySmallModalLabel"
  //       aria-hidden="true"
  //       style={{ height: "auto" }}
  //     >
  //       <div className="modal-dialog modal-dialog-centered">
  //         <div className="modal-content">
  //           <div className="modal-header border-bottom-0">
  //             <h5 className="modal-title mt-0">Product Bulk Upload</h5>
  //             <button
  //               type="button"
  //               className="btn-close"
  //               data-bs-dismiss="modal"
  //               aria-label="Close"
  //             ></button>
  //           </div>
  //           <div className="modal-body">
  //             <p className="text-dark">Upload the CSV file, in correct format</p>
  //             <div className="input-group mb-3">
  //               <input
  //                 type="file"
  //                 className="form-control"
  //                 id="bulkupload"
  //                 onChange={(e) => BulkUploadServiceCenter(e)}
  //               />
  //             </div>
  //             <p className="mb-0">
  //               <a
  //                 href="#"
  //                 className="blueText text-decoration-underline"
  //                 onClick={(e) => download(e)}
  //               >
  //                 Download a Blank Sample Template CSV file htmlFor Bulk upload
  //               </a>
  //             </p>
  //           </div>
  //           <div className="modal-footer text-start border-top-0 d-flex justify-content-end">
  //             <button
  //               type="button"
  //               className="btn addBtns waves-effect waves-light px-4 button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3"
  //               data-bs-dismiss="modal"
  //             >
  //               Cancel
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //   );
  // };

  const employeeViewDetails = () => {
    navigate(`../${AdminRoute.Common.EmployeeViewDetails}`);
  };

  // Side Effect

  useEffect(() => {
    getemployeelistuploadedbycsv();
  }, []);

  return (
    <div id="container">
      <div>
        {" "}
        <Sidebar />
      </div>
      <div style={{ width: "100%" }}>
        <Header title={"Employee Data"} />

        <div className="d-flex justify-content-between px-2 align-items-center">
          <div
            className="gloock-font "
            style={{
              margin: "10px 4px",
              fontSize: 20,
              fontWeight: "bold",
              color: "#125049",
            }}
          >
            My Employee Data
          </div>
          {/* <button
            className="button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3"
            data-bs-toggle="modal"
            data-bs-target=".bulkupload"
          >
            Bulk Upload
          </button> */}

          <div>
            <Button
              onClick={openModal}
              type="submit"
              className="create-new_notes button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3"
            >
              Bulk Upload
            </Button>
          </div>
        </div>

        <span className="my-checklist-modal">
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <button onClick={closeModal} className="close-btn h2 text-white">
              {/* <img src={plusIcon} />{" "} */}+
            </button>
            <div>
              <input
                type="text"
                placeholder="Bulk Uploads"
                className="my-task-add"
                disabled
              />

              <input
                type="File"
                placeholder="Attach File"
                className="my-3 mt-4 border rounded-end w-100"
                name="File"
                id="bulkupload"
                onChange={(e) => BulkUploadServiceCenter(e)}
              />
              {/* <p className="text-danger">{formErrors?.Title}</p> */}

              <p className="mb-4 mt-2">
                <a
                  href="#"
                  className="text-dark text-decoration-underline"
                  onClick={(e) => download(e)}
                >
                  Download a Blank Sample Template CSV File HTML for Bulk Upload
                </a>
              </p>
            </div>
            {/* <button
              type="submit"
              className="button_common w-100 mt-2 mb-0 text-center"
              role="button"
              // onClick={submit}
            >
              Upload test
            </button> */}

            {loading ? <div className="loader_orange"></div> : <></>}
          </Modal>
          {/* **************** View Modal Starts ************************** */}
        </span>

        {/* <div className="col-md-12 mb-2 list-item organization-details-v-height">
          <div className="sessions-tabs">
            <Tabs
              defaultActiveKey="active"
              id="uncontrolled-tab-example"
              className="mb-3 mx-2"
              onSelect={handleSelect}
            >

              <Tab eventKey="active" title="Active">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <div style={styles.conentContainer} className="employee-data-v-height">
                    <Row_ array_={header} color={"#fff"} background={"#125049"} />
                    {getRow()}

                  </div>
                </div>
              </Tab>

              <Tab eventKey="inactive" title="Inactive">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={styles.conentContainer} className="employee-data-v-height">
                    <Row_ array_={header} color={"#fff"} background={"#125049"} />
                    {getRowCSV()}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div> */}

        <div className="col-md-12 mb-2 list-item organization-details-v-height">
          <div className="sessions-tabs">
            <Tabs
              defaultActiveKey="inactive"
              id="uncontrolled-tab-example"
              className="mb-3 mx-2"
              onSelect={handleSelect}
            >
              <Tab eventKey="inactive" title="Inactive">
                <div className="organizations-list mt-4 playground-v-height px-3">
                  <table className="table table-striped">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">##</th>
                        <th scope="col">Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">DOB</th>
                        <th scope="col">Email-id</th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Sector</th>
                        <th scope="col">Leader</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div className="loader_orange"></div>
                      ) : currentItemsinactive.length === 0 ? (
                        <div
                          className="alert alert-warning digital-notes-alert"
                          role="alert"
                        >
                          Data Not Found
                        </div>
                      ) : (
                        currentItemsinactive &&
                        currentItemsinactive.map((val, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{val?.fullname}</td>
                            <td>{val?.gender}</td>
                            <td>{moment(val?.dob).format("LL")}</td>
                            <td>{val?.email}</td>
                            <td>{val?.phone}</td>
                            <td>{val?.designation}</td>
                            <td>{val?.Sector}</td>
                            <td>{val?.lead}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end px-3">
                  <div>
                    <Pagination>
                      <Pagination.First
                        onClick={() => {
                          setCurrentPageinactive(1);
                        }}
                      />
                      <Pagination.Prev
                        onClick={() => {
                          setCurrentPageinactive(currentPage >1 ?currentPage - 1:1);
                        }}
                      />
                      {Array.from({ length: totalPagesinactive }).map(
                        (_, index) => (
                          <Pagination.Item
                            key={index}
                            active={index + 1 === currentPageinactive}
                            onClick={() => handlePageChangeinactive(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        )
                      )}
                      <Pagination.Next
                        onClick={() => {
                          setCurrentPage(currentPageinactive + 1);
                        }}
                      />
                      <Pagination.Last
                        onClick={() => {
                          setCurrentPage(totalPagesinactive);
                        }}
                      />
                    </Pagination>
                    <p className="mx-2 mb-0">
                      Showing {currentPageinactive} of {totalPagesinactive}{" "}
                    </p>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="active" title="Active">
                <div className="organizations-list mt-4 playground-v-height px-3">
                  <table className="table table-striped">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">##</th>
                        <th scope="col">Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">DOB</th>
                        <th scope="col">Email-id</th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Sector</th>
                        <th scope="col">Leader</th>
                        <th scope="col">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Loadingactive ? (
                        <div className="loader_orange"></div>
                      ) : currentItems.length === 0 ? (
                        <div
                          className="alert alert-warning digital-notes-alert"
                          role="alert"
                        >
                          Data Not Found
                        </div>
                      ) : (
                        currentItems &&
                        currentItems.map((val, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{val?.fullname}</td>
                            <td>{val?.gender}</td>
                            <td>{moment(val?.dob).format("LL")}</td>
                            <td>{val?.email}</td>
                            <td>{val?.phone}</td>
                            <td>{val?.designation}</td>
                            <td>{val?.Sector}</td>
                            <td>{val?.lead}</td>
                            <td>
                              <button
                                onClick={() => employeeViewDetails()}
                                className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark text-nowrap"
                              >
                                View Details
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end px-3">
                  <div>
                    <Pagination>
                      <Pagination.First
                        onClick={() => {
                          setCurrentPage(1);
                        }}
                      />
                      <Pagination.Prev
                        onClick={() => {
                          setCurrentPage(currentPage >1 ? currentPage - 1:1);
                        }}
                      />
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      />
                      <Pagination.Last
                        onClick={() => {
                          setCurrentPage(totalPages);
                        }}
                      />
                    </Pagination>
                    <p className="mx-2 mb-0">
                      Showing {currentPage} of {totalPages}{" "}
                    </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        {/* <div style={styles.chartContainer3} className="mt-2 mb-0"> */}
        <div style={styles.chartContainer3}>
          <div>Emergency Contact : 8133 212 2213</div>
          <div>All rights reserved by RYTLife 2023</div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default EmplyeeData;

const styles = {
  // conentContainer: {
  //   backgroundColor: "#e5dfd7",
  //   marginInline: "10px",
  //   width: "100%",
  //   borderRadius: "10px",
  //   height: "100%",
  //   paddingBottom: "10px",
  //   overflow: "auto",
  // },
  // conentContainer1: {
  //   backgroundColor: "#e5dfd7",
  //   marginInline: "10px",
  //   width: "40%",
  //   borderRadius: "10px",
  //   height: "100%",
  //   paddingInline: "20px",
  //   paddingBottom: "10px",
  //   paddingTop: "10px",
  // },
  // chartContainer: {
  //   height: "300px",
  //   margin: "10px 0px",
  //   borderRadius: "10px",
  //   backgroundColor: "#FFF9F1",
  // },
  // chartContainer2: {
  //   height: "300px",
  //   margin: "10px 0px",
  //   borderRadius: "10px",
  //   backgroundColor: "#FFF9F1",
  // },
  chartContainer3: {
    // height: "300px",
    margin: "0px 10px",
    borderRadius: "20px",
    backgroundColor: "#e5dfd7",
    padding: "10px",
    fontSize: 12,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    color: "#858483",
  },
  // chartBox: {
  //   flexDirection: "row",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   paddingInline: "20px",
  //   paddingTop: "10px",
  //   fontWeight: "bold",
  // },
  // blockTitle: {
  //   fon,
  // },
  // button: {
  //   margin: "20px 0px",
  //   fontSize: 14,
  //   padding: "10px 30px",
  //   backgroundColor: "#F2BA3F",
  //   fontWeight: "bold",
  //   borderRadius: "22px",
  //   color: "#fff",
  //   textAlign: "center",
  // },
  // buttonContainer: {
  //   display: "flex",
  //   flexDirection: "row",
  //   margin: "15px 10px",
  //   fontSize: 12,
  //   justifyContent: "space-between",
  //   alignItems: "center",
  // },
  // line: {
  //   minHeight: "100%",
  //   minWidth: "10px",
  //   backgroundColor: "#F2BA3F",
  //   margin: "7px 20px 7px 10px ",
  //   borderRadius: "30px",
  // },
  // date: {
  //   borderRadius: "15px",
  //   backgroundColor: "#FFF9F1",
  //   fontSize: 11,
  //   padding: "3px 10px",
  //   margin: "auto",
  //   fontWeight: "bold",
  //   color: "#858483",
  //   width: "max-content",
  // },
};
