import React, { useState } from "react";
import Sidebar from "./Layout/Sidebar";
import Header from "../Dashboard/Layout/Header";
// import { FiFilter } from "react-icons/fi";
import Calendar from "react-calendar";
import Calender from "./Layout/Calender";
export default function ResponsePlan() {
  const [show, setshow] = useState(false);
  const tableValue = [
    { name: "- Upasana", Department: "Developer", Applied: "28/10/2024" },
    { name: "- Upasana", Department: "HR", Applied: "28/10/2024" },
    { name: "- Upasana", Department: "Admin", Applied: "28/10/2024" },
    { name: "- Upasana", Department: "Accounts", Applied: "28/10/2024" },
  ];

  const instense = () => (
    <div style={styles.chartContainer2} onClick={() => setshow(!show)}>
      <div style={{ padding: "10px 10px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {" "}
          <div style={styles.line} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {" "}
            <div>
              <div style={{ fontWeight: "bold" }}>
                Workshop: Mental Health Awareness
              </div>
              <div style={{ fontSize: 12 }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </div>
            </div>
            {/* =========image======= */}
            <div>
              <img
                src={
                  "https://cdn.fandangoseo.com/wp-content/uploads/2021/03/domain.jpg"
                }
                // alt="new"
                style={{
                  height: "100px",
                  width: "100px",
                  marginInline: "10px",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </div>

        <div style={styles.buttonContainer}>
          <text>
            Conducted by :{" "}
            <text style={{ fontWeight: "bold" }}>Mr. Aniket</text>
          </text>

          <div>
            <text>
              Start Time : <text style={{ fontWeight: "bold" }}>11:00AM</text>
            </text>
            <text>
              {" "}
              | End Time : <text style={{ fontWeight: "bold" }}>11:00AM</text>
            </text>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="container">
      <div>
        {" "}
        <Sidebar />
      </div>
      <div style={{ width: "100%" }}>
        <Header title={"Response Plan"} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // paddingInline: "10px",
          }}
        >
          {" "}
          <div style={styles.conentContainer}>
            {/* === */}
            {instense()}

            {/* ===========================hidde show============================== */}

            {/* ========================show hide================= */}

            {show ? (
              <>
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#125049",
                  }}
                >
                  Participate in this event{" "}
                </div>
                <div>
                  {tableValue.map((val, index) => (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#FFF9F1",
                        margin: "10px 0px",
                        padding: "10px 0px 10px 30px",
                        borderRadius: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: "50%" }}>{val?.name}</div>
                      <div
                        style={{
                          width: "60%",
                          display: "flex",
                        }}
                      >
                        Department -
                        <text style={{ fontWeight: "bold" }}>
                          {val.Department}
                        </text>
                      </div>

                      {/*  */}
                      <div
                        style={{
                          width: "60%",
                          display: "flex",
                        }}
                      >
                        Applied on -
                        <text style={{ fontWeight: "bold" }}>
                          {val.Applied}
                        </text>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {/* === */}
            {instense()}
            {/* ============================================================================================= */}
            <div style={styles.chartContainer3}>
              <div>Emergency Contact : 8133 212 2213</div>
              <div>All rights reserved by RYTLife 2023</div>
            </div>
          </div>
          <div style={styles.conentContainer}>
            <div style={{ fontWeight: "bold" }}>Calender </div>
            <div style={{ fontSize: 12 }}>
              Join the session and make the most of it
            </div>
            {/* ==============calender============== */}
            <div
              style={{
                alignItems: "center",
                // backgroundColor: "pink",
                padding: "0px 50px",
              }}
            >
              <Calender />
            </div>
          </div>
          {/* ==========block 2======= */}
        </div>
      </div>
    </div>
  );
}

const styles = {
  conentContainer: {
    backgroundColor: "#e5dfd7",
    marginInline: "10px",
    width: "50%",
    borderRadius: "10px",
    height: "100%",
    paddingInline: "20px",
    paddingBottom: "10px",
    paddingTop: "10px",
    alignItems: "center",
  },
  chartContainer: {
    height: "300px",
    margin: "10px 0px",
    borderRadius: "10px",
    backgroundColor: "#FFF9F1",
  },
  chartContainer2: {
    // height: "300px",
    margin: "10px 0px",
    borderRadius: "10px",
    backgroundColor: "#FFF9F1",
  },
  chartContainer3: {
    // height: "300px",
    margin: "10px 0px",
    borderRadius: "20px",
    backgroundColor: "#FFF9F1",
    padding: "10px",
    fontSize: 12,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    color: "#858483",
  },
  chartBox: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: "20px",
    paddingTop: "10px",
    fontWeight: "bold",
  },
  blockTitle: {
    // fon,
  },
  button: {
    padding: "5px 30px",
    backgroundColor: "#F2BA3F",
    fontWeight: "bold",
    borderRadius: "20px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "15px 10px",
    fontSize: 12,
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    minHeight: "100%",
    minWidth: "10px",
    backgroundColor: "#F2BA3F",
    margin: "7px 20px 7px 10px ",
    borderRadius: "30px",
  },
  date: {
    borderRadius: "15px",
    backgroundColor: "#FFF9F1",
    fontSize: 11,
    padding: "3px 10px",
    margin: "auto",
    fontWeight: "bold",
    color: "#858483",
    width: "max-content",
  },
};
