import BaseApi from "./BaseApi";

const API = {
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/login`,
        data: props.data,
        tokenRequired: false,
      });
    },
    
    Register: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/createadmin`,
        data: props.data,
        tokenRequired: false,
      });
    },
   ForgotPassword: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/forgot-password`,
        data: props.data,
        tokenRequired: true,
      });
    },

    VerifyViaMail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify-forgot-password`,
        data: props.data,
        tokenRequired: true,
      });
    },

    LoginAPIemail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/resendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },

    NewPassword: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/reset-password`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SelectThemeById: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },



  },

  Common:{
    PlanPlanListAPI: () => {
      return BaseApi({
        method: "GET",
        url: `plan/planlist`,
        tokenRequired: true,
      });
    },

  },

  Payment: {
    RezorPayGetId: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/userpay`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Paymentupdate: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/paymentupdate`,
        data: props.data,
        tokenRequired: true,
      });
    },

      paymentHistory: () => {
        return BaseApi({
          method: "GET",
          url: `/users/paymenthistory`,
          tokenRequired: true,
        });
      },

      Adduserplan: (props) => {
        return BaseApi({
          method: "POST",
          url: `/plan/adduserplan`,
          data: props.data,
          tokenRequired: true,
        });},
    

      MyPlanlfeatures: () => {
        return BaseApi({
          method: "GET",
          url: `/plan/planlfeatures`,
          tokenRequired: true,
        });
      },
    },


    EmplyeeData: {
      Employeecsvupload: (props) => {
        return BaseApi({
          method: "POST",
          url: `/users/user_upload`,
          data: props.data,
          tokenRequired: true,
        });
      },
   
      EmployeeList: (props) => {
        return BaseApi({
          method: "GET",
          url:`superadmin/viewEmployee?page=1&size=50&Companyname=${props.cname}&isactive=true`,
          tokenRequired: true,
        });
      },

      EmployeeListbycsv: (props) => {
        return BaseApi({
          method: "GET",
          url: `users/csvUploadedUsers?page=1&key=Cmsemployee&size=50&Companyname=${props.cname}&isActive=1`,
          tokenRequired: true,
        });
      },
  }
}

export default API;
