import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
// const Dashboard = lazy(() =>import("../Components/Dashboard/Dashboard"));
import ForgotPassword from "./../Components/Auth/ForgotPassword";
import VerifyOtp from "./../Components/Auth/VerifyOtp";
import NewPassword from "./../Components/Auth/NewPassword";
import SelectPlan from "./../Components/Auth/SelectPlan";
import Register from "../Components/Auth/Register";
import Dashboard from "../Components/Dashboard/Dashboard";
import Subscription from "../Components/Dashboard/Subscription";
import NewSubscription from "../Components/Dashboard/NewSubscription";
import EmplyeeData from "../Components/Dashboard/EmployeeData";
import EmployeeViewDetails from "../Components/Dashboard/EmployeeViewDetails";
import ResponsePlan from "../Components/Dashboard/ReponsePlan";
import Register1 from "../Components/Auth/Register1";
const AdminRoute = [
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
  {
    path: RouteName.Auth.ForgotPassword,
    element: <ForgotPassword />,
    exact: true,
  },
  {
    path: RouteName.Auth.VerifyOtp,
    element: <VerifyOtp />,
    exact: true,
  },
  {
    path: `${RouteName.Auth.NewPassword}/:otpid`,
    element: <NewPassword />,
    exact: true,
  },
  {
    path: RouteName.Auth.Register,
    element: <Register />,
    exact: true,
  },
  {
    path: RouteName.Auth.Register1,
    element: <Register1 />,
    exact: true,
  },
  {
    path: RouteName.Common.SelectPlan,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <SelectPlan />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Dashboard,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Dashboard />,
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Subscription,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Subscription />,
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.NewSubscription,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <NewSubscription />,
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.EmplyeeData,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <EmplyeeData />,
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.EmployeeViewDetails,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <EmployeeViewDetails />,
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.ResponsePlan,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ResponsePlan />,
      </ProtectedRoute>
    ),
    exact: true,
  },

  // {
  //   path: RouteName.Dashboard.path,
  //   // element:<h1>Dashboard</h1>,
  //   element:<Dashboard/>,
  //   exact: true,
  // },
];

export default AdminRoute;
