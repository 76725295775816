import React, { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import axios from "axios";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const AuthToken = localStorage.getItem("AuthTokenORG-admin");
  const [isSubmit, setIsSubmit] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("ORGadminData"));
  const MYEmail = localStorage.getItem("myMail");
  const [ApiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(UserData?.data?.email);
  }, []);

  function handleChange(event, index) {
    const { value } = event.target;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  }
  const handleKeyDown = (event, index) => {
    const { value } = event.target;
    if (event.key === "Backspace") {
      if (value.length === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    try {
      API.Auth.VerifyViaMail({
        data: {
          email: MYEmail,
          role: "Orgadmin",
          password_reset_key: newotp,
        },
      }).then((response) => {
        console.log(response.data);
        if (response?.data?.status === 1) {
          navigate(`../${AdminRoute?.Auth?.NewPassword}/${newotp}`);
          toast.success(response?.data?.message);
        } else toast.error(`${response.data.message}`);
      });
    } catch (error) {
      console.log(error);
    }
    setIsSubmit(false);
  };


  const handleResendOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      API.Auth.LoginAPIemail({
        data: {
          email:MYEmail,
          role: "Orgadmin",
          resend: true,
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
        } else {
          setApiError(response?.data?.message);
        }
        setLoading(false);
        setOtp(["", "", "", ""]);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto">
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex">
        <div className="col-12 col-md-6">
          <img className="logo" width={200} src={rtylifeLogo} />
          <h1 className="heading">Reclaim control over your</h1>
          <p className="subheading">Mental Health journey</p>
          <p>
            through our online therapy programs, guided by our compassionate
            team of psychologists.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <div className="register p-4 py-5">
            <form>
              <h2 className="heading fw-600 pb-2 mt-0">Verify via OTP</h2>
              <div className="col">
                <label className="pb-2">
                  * Enter 4 digit OTP sent on the registered{" "}
                  <b>
                    {UserData?.data?.email ? UserData?.data?.email : MYEmail}{" "}
                  </b>{" "}
                  E-maid Id
                </label>
                <div className="otp-container text-center my-2">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      placeholder="-"
                      autoComplete="off"
                      type="number"
                      id={`otp-${index}`}
                      name={`otp-${index}`}
                      value={value}
                      maxLength={1}
                      className="otp-input"
                      onChange={(event) => handleChange(event, index)}
                      onKeyDown={(event) => handleKeyDown(event, index)}
                      ref={inputRefs[index]}
                    />
                  ))}
                </div>
                {/* <p className="text-danger">Errors</p> */}
              </div>

              <NavLink
                type="submit"
                className="button_common w-100 my-3 mb-3 text-center"
                // to="/NewPassword"
                onClick={(e) => handleVerifyOtp(e)}
              >
                Verify
              </NavLink>
              <NavLink
                className="text-center register-text"
                onClick={(e) => handleResendOtp(e)}

              >
                Resend OTP{" "}
              </NavLink>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
