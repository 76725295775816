import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminRoute from "./../../Route/RouteDetails";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import { NavLink } from "react-router-dom";
import API from "../../Api/Api";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [FormError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  // email validation for forget password
  const validate = (email) => {
    const err = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!email) {
      err.email = "Please enter your email address.";
    } else if (!regex.test(email)) {
      err.email = "Incorrect email Format.";
    }
    return err;
  };

  //Api call
  useEffect(() => {
    if (Object.keys(FormError).length === 0 && isSubmit) {
      setLoading(true);
      try {
        API.Auth.ForgotPassword({
          data: {
            email: email,
            role: "Orgadmin",
          },
        }).then((response) => {
          if (response?.data?.data?.status === 1) {
            console.log(response?.data);
            toast.success(response?.data?.message);
            navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.message);
        setIsSubmit(false);
        setLoading(false);
      }
    }
  }, [FormError]);

  //Api call
  const getForgotPassword = (e) => {
    e.preventDefault();
    localStorage.setItem("myMail", email);
    setFormError(validate(email));
    setIsSubmit(true);
  };

  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto">
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex">
        <div className="col-12 col-md-6">
          <img className="logo" width={200} src={rtylifeLogo} />
          <h1 className="heading">Reclaim control over your</h1>
          <p className="subheading">Mental Health journey</p>
          <p>
            through our online therapy programs, guided by our compassionate
            team of psychologists.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <div className="register p-4 py-5">
            <form>
              <h2 className="heading fw-600 pb-2 mt-0 left-arrow ">
                <span
                  className="me-2 cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                </span>
                Forgot Password
              </h2>
              <div className="col">
                <label className="pb-2">
                  * Enter registered work E-Mail ID
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Please enter your email address"
                />
                <p className="text-danger">{FormError?.email}</p>
              </div>

              <button
                type="submit"
                className="button_common w-100 my-3 mb-3 text-center"
                // to="/VerifyOtp"
                onClick={(e) => getForgotPassword(e)}
              >
                Verify Via OTP
              </button>
              {loading ? <div className="loader_orange"></div> : <></>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
