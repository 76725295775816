import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import { NavLink } from "react-router-dom";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const NewPassword = () => {
  //state
  const navigate = useNavigate();
  const params = useParams();
  const initialValues = { NewPass: "", ConfirmPass: "" };
  const [isSubmit, setIsSubmit] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("ORGadminData"));
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const MYEmail = localStorage.getItem("myMail");

  //function 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/i;
    const errors = {};

    if (!values?.NewPass) {
      errors.NewPass = "Field is Required!";
    } else if (!regex.test(values.NewPass)) {
      errors.NewPass =
        "Password must contain at least 8 characters, 1 number, 1 upper and 1 lowercase!";
    }
    if (!values?.ConfirmPass) {
      errors.ConfirmPass = "Field is Required!";
    } else if (formValues.NewPass !== formValues.ConfirmPass) {
      errors.ConfirmPass = "Password does not match!";
    }
    return errors;
  };

  // api call
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    setIsSubmit(true)
    try {
      API.Auth.NewPassword({
        data: {
          email: MYEmail,
          role: "Orgadmin",
          password_reset_key: params?.otpid,
          password: formValues?.ConfirmPass
        },
      }).then((response) => {
        console.log(response.data);
        if (response?.data?.status === 1) {
           navigate(`../${AdminRoute?.Auth?.Login}`);
          toast.success(response?.data?.message
          )
        }
        else toast.error(`${response.data.message}`);
      });
    } catch (error) {
      console.log(error);
    }
    setIsSubmit(false)

  };
}, [formErrors]);

//api call
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormErrors(validate(formValues));
  };

  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto">
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex">
        <div className="col-12 col-md-6">
          <img className="logo" width={200} src={rtylifeLogo} />
          <h1 className="heading">Reclaim control over your</h1>
          <p className="subheading">Mental Health journey</p>
          <p>through our online therapy programs, guided by our compassionate team of psychologists.</p>
        </div>
        <div className="col-12 col-md-6">
          <div className="register p-4 py-5">
            <form className="position-relative">
              <h2 className="heading fw-600 pb-2 mt-0">New Password</h2>
              <NavLink className="position-absolute closeBtn" to="/VerifyOtp">X</NavLink>
              <div className="mb-2 float-start w-100 position-relative">
              <label className="pb-2">
                  * Enter New Password
                </label>
                {/* <div className=""> */}
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control"
                  id="newpassword"
                  name="NewPass"
                  required
                  onChange={handleChange}
                  placeholder="Enter new password."
                  // onKeyUp= {(e)=> check(e)}
                />
                <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  ></i>
                </span>
                {/* </div> */}
                <p className="text-danger mb-1">{formErrors?.NewPass}</p>
              </div>
              <div className="mb-2 float-start w-100 position-relative">
              <label className="pb-2">
                  * Confirm New Password
                </label>
                {/* <div className="position-relative"> */}
                <input
                  className="form-control"
                  // onKeyUp= {(e)=> check(e)}
                  type={passwordShown2 ? "text" : "password"}
                  id="conformpassword"
                  name="ConfirmPass"
                  required
                  onChange={handleChange}
                  placeholder="Enter confirm password"
                />
                <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown2(!passwordShown2)}
                  ></i>
                </span>
                {/* </div> */}
                <p className="text-danger mb-1">{formErrors?.ConfirmPass}</p>
              </div>

              <button
                type="submit"
                className="button_common w-100 my-3 mb-3 text-center"
                onClick={(e) => handleSubmit(e)}
              >
                Re-Set Password
              </button>
           {/* <div className="col">
                <label className="pb-2">
                  * Enter New Password
                </label>
                <input
                  type={passwordShown ? "text" : "password"}
                  id="newpassword"
                  name="NewPass"
                  placeholder="Enter your New Password"
                  onChange={handleChange}
                  autoComplete="off"
                />
                 <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  ></i>
                </span>

                
                <p className="text-danger">Errors</p>
              </div> */}
{/* 
              <div className="col">
                <label className="pb-2">
                  * Confirm New Password
                </label>
                <input
             type={passwordShown2 ? "text" : "password"}
                  id="conformpassword"
                  name="ConfirmPass"
                  placeholder="Re-Enter your Password"
                  onChange={handleChange}
                  autoComplete="off"
                />
                 <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown2(!passwordShown2)}
                  ></i>
                </span>
                <p className="text-danger">Errors</p>
              </div> */}

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
