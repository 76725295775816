import React, { useEffect, useState } from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import useRazorpay from "react-razorpay";
import Button from "react-bootstrap/Button";

const SelectPlan = () => {
  //state
  const [loading, setLoading] = useState(false);
  const [PlanList, setPlanList] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("ORGadminData"));
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const PlanUpgrade = queryParams.get("plan");

  const Logoutfun = () => {
    // localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenORG-admin");
    localStorage.removeItem("ORGadminData");
    navigate(`../${AdminRoute?.Auth?.Login}`);
  };

  //api call
  useEffect(() => {
    // setLoading(true)
    try {
      API.Common.PlanPlanListAPI({}).then((response) => {
        if (response.data.status === 1) {
          // console.log(response?.data?.data);
          setPlanList(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  // select plan api call
  const handlePlan = (plan_id) => {
   
   
    API.Auth.SelectThemeById({
      data: {
        plan_id: plan_id?._id,
      },
      UserId: UserData?.data?._id,
    })
      .then((response) => {
        if (response.data.status) {
          // Call Razorpay API to get order ID
        }
      })
      .catch((error) => {
        console.log(error);
      });
    API.Payment.RezorPayGetId({
      data: {
        name: UserData?.data.fullname,
        created_by: UserData?.data?._id,
        amount: plan_id.amount,
      },
    })
      .then((response) => {
        console.log("users/userpay", response?.data?.message);
        if (response.data.message === "successfully") {
          RezorPaySdk(response?.data?.data, plan_id, response?.data?.key_id);
          // showRazorpay(response.data.data.id, plan_id.amount, plan_id,)
        }
      })
      .catch((error) => {
        console.log(error);
      });
    
  };

  // const handleRazorpaySuccess = (response, plan_id) => {
  //   // Handle successful Razorpay payment
  //   console.log("Razorpay payment successful:", response);

  //   // Now navigate to the dashboard
  //   navigate(`../${AdminRoute?.Common?.Dashboard}`);
  // };

  const RezorPaySdk = (orderID, plan_id, appKey) => {
    const options = {
      key: appKey, // Enter the Key ID generated from the Dashboard
      // key: "rzp_live_odscnZxNRoUPIm",
      amount: orderID?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: orderID?.currency,
      name: "RYT",
      description: orderID?.notes?.description
        ? orderID?.notes?.description
        : "Test",
      image: "https://example.com/your_logo",
      order_id: orderID.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        console.log(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        handleRezorpayResponce(
          response,
          orderID.id,
          orderID.amount,
          "finish",
          plan_id
        );
      },
      prefill: {
        name: UserData?.data?.fullname,
        email: UserData?.data?.email,
        contact: UserData?.data?.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      handleRezorpayResponce(
        response,
        orderID,
        orderID?.amount,
        "decline",
        plan_id
      );
    });
    rzp1.open();
  };

  const handleRezorpayResponce = (
    response,
    orderID,
    amount,
    status,
    plan_id
  ) => {
    API.Payment.Paymentupdate({
      data: {
        order_id: orderID,
        name: UserData?.data?.fullname,
        created_by: UserData?.data?._id,
        // amount: amount,
        amount: Math.floor(amount / 100),
        responce_obj: response,
        order_status: status,
        paymentfeatures: plan_id?.name,
      },
    })
      .then((response) => {
        console.log("object payment update", response);
        console.log(response.data.status);

        if (
          response.data.status === 1 ||
          response.data.message === "updated successfully"
        ) {
          handleRezorpayResponce(response);
          navigate(`../${AdminRoute?.Common?.Dashboard}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const handlePlanSave = (plan_id) => {
  //   API.Payment.Adduserplan({
  //     data: {
  //       planid: plan_id._id,
  //       isupdate: PlanUpgrade ? true : false,
  //     },
  //     UserId: UserData?.data?._id,
  //   })
  //     .then((response) => {
  //       if (response.data.status) {
  //         if (plan_id.name === "Pay per Feature") {
  //           if (PlanUpgrade) {
  //             navigate(`../${AdminRoute?.Common.Dashboard}`);
  //           } else {
  //             //  navigate(`../${AdminRoute?.Common?.Theam}?plan=2`);
  //             navigate(`../${AdminRoute?.Common.Dashboard}`);

  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  

  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto position-relative">
      <div className="position-absolute top-0 end-0 logout-icon">
        <Button
          type="submit"
          className="button_common m-0 d-flex align-items-center logout-btn px-4"
          onClick={() => Logoutfun()}
        >
          Logout <i className="fa fa-sign-out ms-2"></i>
        </Button>
      </div>
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex ">
        <div className="col-12 col-md-12 text-center">
          <h1 className="heading fw-600 pb-2 mt-0 text-center">Select Plan</h1>
          <p>
            Help us serve you better by selecting a plan that suits you best
          </p>
        </div>
        {PlanList &&
          PlanList?.map((val) => (
            <div className="col-12 col-md-4" key={val._id}>
              <div
                className="selectPlan rounded-5"
                onClick={() => handlePlan(val)}
              >
                <div
                  className={
                    val?.name === "BASIC Plan" ? (
                      "planName p-4 rounded-5 rounded-bottom-0 text-white"
                    ) : val?.name === "PRO Plan" ? (
                      "planName2 p-4 rounded-5 rounded-bottom-0 text-white"
                    ) : val?.name === "Pay Per Feature" ? (
                      "planName3 p-4 rounded-5 rounded-bottom-0 text-dark"
                    ) : (
                      <></>
                    )
                  }
                >
                  <h1>{val?.name}</h1>
                  <h4>Rs. {val?.amount}/-</h4>
                  <h6>Monthly Subscription</h6>
                </div>
                <div className="planBody p-4">
                  <h4 className="mb-4">Features</h4>
                  {val?.feature?.length &&
                    Object.entries(val?.feature[0]).map(
                      ([key, value], index) => {
                        return (
                          <p>
                            <i className="bi bi-check-lg me-2"></i>
                            {key}
                          </p>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SelectPlan;
