// Header.js
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from "react-bootstrap/Dropdown";
import Sidebar from "../Layout/Sidebar";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import user from "../../../assets/images/user.png";
import { AiOutlineBell } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
const Header = (props) => {
  const ORGadminData = JSON.parse(localStorage.getItem("ORGadminData"));

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  console.log("ORGadminData=====header", ORGadminData);
  const NotificationList = () => {
    return;
  };
  // const Info = () => {
  //   return <div>dsdsadadadadad</div>;
  // };

  return (
    <div id="header">
      <div id="titleStyle" className="d-flex">
        {props?.title ? (
          props?.title
        ) : (
          <>
            Welcome{" "}
            <div id="headername" className="ms-2">
              {ORGadminData?.data?.fullname}!{" "}
            </div>
          </>
        )}
      </div>
      <div id="headerIconGroup" className="align-items-center navbar-expand-lg">
        {/* <div
          id="headerIcon"
          onClick={() => [setShow1(!show1), setShow2(false)]}
        >
          <AiOutlineBell size={"2em"} />
        </div>
        <div
          id="headerIcon"
          className="me-2"
          onClick={() => [setShow2(!show2), setShow1(false)]}
        >
          <AiOutlineUser size={"2em"} />
        </div>
        {show1 && <div id="modal1">msfmbsdmfsbmdbmbf</div>}
        {show2 && <Info />} */}

        <ul className="navbar-nav ms-auto mt-lg-0 align-items-center">
          <li className="notification-dropdown">
            <Dropdown>
              {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className='icon-div p-0 pt-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-lg" width={"25"} role="img"><path fill="var(--ci-primary-color, currentColor)" d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z" className="ci-primary"></path></svg>
                </div>
              </Dropdown.Toggle> */}
              {/* <Dropdown.Menu className="px-2 pt-2">
                <Dropdown.Item >
                  <div className="preview-item-joined flex-grow py-1">
                    <div className="notification-wrapper ps-2">
                      <p className="preview-subject ellipsis font-weight-medium text-dark">title</p>
                      <p className="fw-light small-text mb-0 grey-text">description</p>
                      <p className='date grey-text'>createdAt</p>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu > */}
            </Dropdown>
          </li>

          <li className="account-dropdown">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="icon-div">
                  {/* <img src='images/user-white.png' className="User" alt="icon" /> */}
                  <AiOutlineUser size={"1.5em"} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <p className="mb-0">{ORGadminData?.data?.org_name}</p>
                <p className="mb-0">{ORGadminData?.data?.email}</p>
                <p className="mb-0">{ORGadminData?.data?.Companyemail}</p>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </div>

    // <div className="header">
    //   <div className="toggle-menu">
    //     <Dropdown className="d-inline" autoClose={false}>
    //       <Dropdown.Toggle id="dropdown-autoclose-false">

    //       </Dropdown.Toggle>

    //   <Button variant="primary" onClick={handleShow}>

    //   <Button className="btn mb-logo" id="collapseToggle">
    //           <span id="toggleIcon" className="navbar-toggler-icon" />
    //         </Button>

    //   </Button>

    //   <Offcanvas show={show} onHide={handleClose}>
    //     <Offcanvas.Header closeButton>
    //     </Offcanvas.Header>
    //     <Offcanvas.Body>
    //     <Sidebar />
    //     </Offcanvas.Body>
    //   </Offcanvas>

    //       <Dropdown.Menu>

    //       </Dropdown.Menu>
    //     </Dropdown>
    //   </div>
    //   <ul className="navbar-nav ms-auto mt-lg-0 align-items-center">
    //     <li className='notification-dropdown'>
    //       <Dropdown>
    //         <Dropdown.Toggle variant="success" id="dropdown-basic">
    //           <div className='icon-div p-0'>
    //             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-lg" role="img"><path fill="var(--ci-primary-color, currentColor)" d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z" className="ci-primary"></path></svg>
    //           </div>
    //         </Dropdown.Toggle>
    //         <Dropdown.Menu>
    //           <Dropdown.Item>
    //             <span className="badge-div mb-1 mt-2">Today</span>
    //           </Dropdown.Item>
    //           <Dropdown.Item>
    //             <div className="preview-item-joined flex-grow py-1">
    //               <p className="preview-subject ellipsis font-weight-medium div-dark">3 people joined your &apos;Fight Anxiety&apos; group</p>
    //               <p className="fw-light small-div mb-0 grey-div"> Congratulations! Your support group is here </p>
    //               <p className='date grey-div'>Today 2:00 pm</p>
    //             </div>
    //           </Dropdown.Item>

    //           <Dropdown.Item>
    //             <div className="preview-item-joined flex-grow py-1">
    //               <p className="preview-subject ellipsis font-weight-medium div-dark">3 people joined your &apos;Fight Anxiety&apos; group</p>
    //               <p className="fw-light small-div mb-0"> Congratulations! Your support group is here </p>
    //               <p className='date'>Today 2:00 pm</p>
    //             </div>
    //           </Dropdown.Item>

    //           <Dropdown.Item>
    //             <div className="preview-item-joined flex-grow py-1">
    //               <p className="preview-subject ellipsis font-weight-medium div-dark">3 people joined your &apos;Fight Anxiety&apos; group</p>
    //               <p className="fw-light small-div mb-0"> Congratulations! Your support group is here </p>
    //               <p className='date'>Today 2:00 pm</p>
    //             </div>
    //           </Dropdown.Item>

    //         </Dropdown.Menu>
    //       </Dropdown>
    //     </li>

    //     <li className='account-dropdown'>
    //       <Dropdown>
    //         <Dropdown.Toggle variant="success" id="dropdown-basic">
    //           <div className='icon-div'><img src={user} className="User" alt="icon" /></div>
    //         </Dropdown.Toggle>
    //         <Dropdown.Menu>
    //           < NavLink >My account</NavLink><br></br>
    //           < NavLink >Payments</NavLink><br></br>
    //           < NavLink >Settings</NavLink><br></br>

    //           {/* <Dropdown.Item href="/payment"></Dropdown.Item>
    //           <Dropdown.Item href="/settings"> </Dropdown.Item> */}
    //         </Dropdown.Menu>
    //       </Dropdown>
    //     </li>

    //   </ul>
    // </div>
  );
};

export default Header;
