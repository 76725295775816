const RouteName = {
  Auth: {
    Login: "/Login",
    ForgotPassword: "/ForgotPassword",
    VerifyOtp: "/VerifyOtp",
    NewPassword: "/NewPassword",
    Register: "/Register",
    Register1:"/Register1"
  },
  Common: {
    SelectPlan: "/SelectPlan",
    Dashboard: "/",
    AdminSettings: "/AdminSettings",
    Subscription: "/Subscription",
    NewSubscription: "/NewSubscription",
    EmplyeeData: "/EmplyeeData",
    EmployeeViewDetails: "/EmployeeViewDetails",
    ResponsePlan: "/ResponsePlan",
  },

  PageNotFound: "*",
};

export default RouteName;
