import { useState } from "react";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

export default function Calender() {
  const [value, setvalue] = useState(new Date());
  const onChange = (e) => {
    // alert(e);
    setvalue(e);
  };

  return (
    <div>
      <Calendar calendarType="gregory" onChange={onChange} value={value} />
    </div>
  );
}
