import React, { useState, useEffect } from "react";
import Sidebar from "./Layout/Sidebar";
import Header from "../Dashboard/Layout/Header";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails"
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import moment from "moment";
function NewSubscription() {
    const navigate = useNavigate()

    const [paymenList, setPaymentList] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [PlanDetails, setPlanDetails] = useState({});
    const [CurrentPlan, setCurrentPlan] = useState([]);

    useEffect(() => {
        MyPlanlfeaturesss();
        getCurrentSession();
    }, []);
    const getCurrentSession = () => {
        setLoading(true)
        try {
            API.Payment.paymentHistory().then((response) => {
                if (response.status === 200) {
                    console.log("", response.data.data_all);
                    setPaymentList(response.data.data_all);
                }
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const MyPlanlfeaturesss = () => {
        try {
            API.Payment.MyPlanlfeatures()
                .then((response) => {
                    if (response.status === 200) {
                        setCurrentPlan(response.data.data)
                        setPlanDetails(response.data.plandetails)
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }


    const handleUpgradePlan = () => {
        navigate(`../${AdminRoute.Common.SelectPlan}?plan=upgrade`)
    }

    //jsx
    return (
        <div id="container">
            <div>
                <Sidebar />
            </div>
            <div style={{ width: "100%" }}>
                <Header title={"Subscription"} />
                <div className="col-12 px-3">
                    <div className="row">
                        <p className='text-muted mt-0 mb-2'>Below are your profile details</p>
                        <div className="col-lg-8 col-md-12 mb-2">
                            <div className="row h-100">
                                <div className="col-md-12 list-item">
                                    <div className="payment-table-height">
                                        <div className="dashboard-inner pb-2">
                                            <div className="row payment-table">
                                                <div className='table-responsive'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Feature</th>
                                                                <th scope="col">Price</th>
                                                                <th scope="col">Payment Date</th>
                                                                <th scope="col">Expiry Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Loading ?
                                                            <div className='mt-3 w-100 mx-auto'>
                                                                <div className="container_loader"></div>
                                                            </div>
                                                            :
                                                            paymenList.length === 0 ?
                                                                <div className="alert alert-warning" role="alert">
                                                                    Data Not Found
                                                                </div>
                                                                :
                                                                paymenList &&
                                                                paymenList?.map((val) => {
                                                                    return (
                                                            <tr
                                                            key={val.order_id}
                                                            >
                                                                <th scope="row">
                                                                    {val.order_id}
                                                                    </th>
                                                                <td className='text-center'>
                                                                    {val.paymentfeatures}
                                                                    </td>
                                                                <td className='text-center'>
                                                                    Rs. {val.amount}/-
                                                                    </td>
                                                                <td className='text-center'>
                                                                    {moment(val.createdAt).format('DD/MM/YYYY')}
                                                                    </td>
                                                                <td className='text-center'>
                                                                    {moment(val.enddate).format('DD/MM/YYYY')}
                                                                    </td>
                                                            </tr>
                                                             )
                                                                })
                                                        } 
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12  mb-2">
                            <div className="update-subscription h-100">
                                <div className="h-100">
                                    <p className='secheading mb-1 mt-0 gloock-font'>My Current Plan</p>
                                    <div className='plan-box'>
                                        <div className="pricing-card h-100">
                                            <div className="pricing-card-heading pricing-card-heading-pro p-4">
                                                <h3 className="black-text">
                                                     {PlanDetails?.name} 
                                                    </h3>
                                                <h3 className="black-text">Rs.{PlanDetails?.amount}</h3>
                                                <p className='mb-0'>Monthly subscription:</p>
                                            </div>
                                            <h5 className="mt-3 text-start features-heading">Features</h5>
                                            <ul className="pricing-card-list">
                                                {PlanDetails?.feature?.length && Object.entries(PlanDetails?.feature[0]).map(([key, value], index) => {
                                                return (
                                                    <li key={index} className={`pricing-card-list-item ${value ? "icon-yes" : "icon-no"}`}>{key}</li>
                                                )
                                            })
                                            }

                                                {/* <li li className="pricing-card-list-item icon-no">+ 4sessions: 1-1 with Therapist</li>
                                                <li className="pricing-card-list-item icon-no">Join 2 Groups</li>
                                                <li className="pricing-card-list-item icon-no">Al Therapist</li>
                                                <li className="pricing-card-list-item icon-no">Digital Notes</li>
                                                <li className="pricing-card-list-item icon-yes">1 Themes: Light</li>
                                                <li className="pricing-card-list-item icon-yes"> Playground</li> */}

                                            </ul>
                                            <div className="bg-white d-block px-4 pb-4">
                                                <Button type="submit" className="button_common m-0"
                                                    // disabled={PlanDeatils?.name === "PRO Plan" ? true : false}
                                                    onClick={() => handleUpgradePlan()}><span className="text-white">Upgrade Plan</span></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* col-lg- */}
                    </div>
                </div>


                <div style={styles.chartContainer3}>
                    <div>Emergency Contact : 8133 212 2213</div>
                    <div>All rights reserved by RYTLife 2023</div>
                </div>
            </div>
        </div>
    );
}

export default NewSubscription;

const styles = {
    conentContainer: {
        backgroundColor: "#e5dfd7",
        marginInline: "10px",
        width: "60%",
        borderRadius: "10px",
        height: "100%",
        paddingInline: "20px",
        paddingBottom: "10px",
        paddingTop: "10px",
        maxHeight: "60vh",
        overflow: "auto",
    },
    conentContainer1: {
        backgroundColor: "#e5dfd7",
        marginInline: "10px",
        width: "40%",
        borderRadius: "10px",
        height: "100%",
        paddingInline: "20px",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    chartContainer: {
        height: "300px",
        margin: "10px 0px",
        borderRadius: "10px",
        backgroundColor: "#FFF9F1",
    },
    chartContainer2: {
        // height: "300px",
        margin: "10px 0px",
        borderRadius: "10px",
        backgroundColor: "#FFF9F1",
    },
    chartContainer3: {
        // height: "300px",
        margin: "30px 10px",
        borderRadius: "20px",
        backgroundColor: "#e5dfd7",
        padding: "10px",
        fontSize: 12,
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
        fontWeight: "bold",
        color: "#858483",
    },
    chartBox: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingInline: "20px",
        paddingTop: "10px",
        fontWeight: "bold",
    },
    blockTitle: {
        // fon,
    },
    button: {
        margin: "20px 0px",
        fontSize: 14,
        padding: "10px 30px",
        backgroundColor: "#F2BA3F",
        fontWeight: "bold",
        borderRadius: "22px",
        color: "#fff",
        textAlign: "center",
        border: "none"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        margin: "15px 10px",
        fontSize: 12,
        justifyContent: "space-between",
        alignItems: "center",
    },
    line: {
        minHeight: "100%",
        minWidth: "10px",
        backgroundColor: "#F2BA3F",
        margin: "7px 20px 7px 10px ",
        borderRadius: "30px",
    },
    date: {
        borderRadius: "15px",
        backgroundColor: "#FFF9F1",
        fontSize: 11,
        padding: "3px 10px",
        margin: "auto",
        fontWeight: "bold",
        color: "#858483",
        width: "max-content",
    },
};
