import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import { NavLink } from "react-router-dom";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";

const Login = () => {
  const navigate = useNavigate();
  const initialValues = { InputType: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ApiError, setApiError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validate = (values) => {
    const errors = {};
    if (!values?.InputType) {
      errors.InputType = "Enter Valid Email ID!";
    }
    if (emailRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Email ID !";
    }
    if (!values?.password) {
      errors.password = "Enter Password!";
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (emailRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIEmail({
            data: {
              email: formValues?.InputType,
              password: formValues?.password,
              role: "Orgadmin",
            },
          }).then((response) => {
            console.log("LOgin api =====>", response.data);

            if (response?.data?.status === 1) {
              // console.log("Status 1");
              if (response?.data?.data?.plan_id) {
                // console.log("go to dashboad and set local storage data");
                localStorage.setItem(
                  "AuthTokenORG-admin",
                  response?.data?.data?.token
                );
                localStorage.setItem(
                  "ORGadminData",
                  JSON.stringify(response?.data)
                );
                navigate(`../${AdminRoute.Common.Dashboard}`);
              } else {
                // console.log("go to select plans");
                localStorage.setItem(
                  "AuthTokenORG-admin",
                  response?.data?.data?.token
                );
                localStorage.setItem(
                  "ORGadminData",
                  JSON.stringify(response?.data)
                );
                navigate(`../${AdminRoute?.Common?.SelectPlan}`);
              }
            } else {
              // console.log("go to registration");
              // console.log("Status 0");
              if (response?.data?.message === "User does not exist.") {
              navigate(`../${AdminRoute?.Auth?.Register1}?email=${JSON.stringify(formValues?.InputType)}`);
                
              }

              // toast.error(response?.data?.message);
              setApiError(response?.data?.message);
            }

            setLoading(false);
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
         
          setApiError(error.message);
        }
        setIsSubmit(false);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  //jsx
  return (
    <div className="container h-100 d-block d-sm-flex py-auto">
      <div className="row h-100 w-100 align-items-center d-block d-sm-flex">
        <div className="col-12 col-md-6">
          <img className="logo" width={200} src={rtylifeLogo} />
          <h1 className="heading">Reclaim control over your</h1>
          <p className="subheading">Mental Health journey</p>
          <p>
            through our online therapy programs, guided by our compassionate
            team of psychologists.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <div className="register p-4 py-5">
            <form>
              <h2 className="heading fw-600 pb-2 mt-0">Login</h2>
              <div className="col">
                <label className="pb-2">* Enter registered E-Mail ID</label>
                <input
                  type="text"
                  name="InputType"
                  value={formValues?.InputType}
                  placeholder="Enter your E-Mail ID"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.InputType}</p>
              </div>

              


              <div className="mb-2 float-start w-100 position-relative">
              <label className="pb-2">
                  *Enter Password
                </label>
                {/* <div className=""> */}
                <input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={formValues?.password}
                  placeholder="Enter your Password"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <span className="formIcons ">
                  <i
                    toggle="#password-field"
                    className="fa fa-fw field-icon toggle-password fa-eye"
                    onClick={() => setPasswordShown(!passwordShown)}
                  ></i>
                </span>
                {/* </div> */}
                <p className="text-danger">{formErrors?.password}</p>

              </div>

              <NavLink
                className="register-text"
                to={AdminRoute?.Auth?.Register1}
              >
                Let&apos;s Register{" "}
              </NavLink>
              <NavLink className="text-end register-text" to="/ForgotPassword">
                Forgot Password{" "}
              </NavLink>
              <p className="text-danger">{ApiError}</p>

              <NavLink
                type="submit"
                className="button_common w-100 my-3 mb-3 text-center"
                // to={AdminRoute?.Common?.SelectPlan}
                onClick={(e) => handleSubmit(e)}
              >
                Login
              </NavLink>

              {loading ? <div className="loader_orange"></div> : <></>}
              {/* <div className="form-group">
                <div className="custom-control custom-checkbox d-flex align-item-center ">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                  />
                  <label
                    className="custom-control-label ps-2"
                    htmlFor="customCheck1"
                  >
                    Stay logged in
                  </label>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
